@use '../../../base_css/abstracts/abstracts';

#market-simulator {
  position: fixed;
  top: 30%;
  left: -320px;
  z-index: 10000;
  width: 320px;
  padding: 20px;
  border: 5px solid var(--c_primary);
  background: var(--c_white);
  transition: all 0.3s ease;

  &.opened {
    left: 0;
  }

  .title {
    position: absolute;
    bottom: 0;
    right: -5px;
    transform: rotate(90deg);
    transform-origin: 100% 100%;
    cursor: pointer;
    padding: 5px 15px;
    background: var(--c_primary);
    color: var(--c_white);
    font-size: 16px;
  }

  .input {
    display: block;
  }

  .label {
    display: block;
    color: var(--c_blue_700);
    font-family: var(--font-serif);
  }

  select {
    margin: 5px 0;
  }

  .btn-primary {
    margin: 10px 15px 0 0;
  }
}
