@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

@include commonStyles {
  @keyframes fancybox-rotate {
    from {
      transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes fancybox-rotate {
    from {
      transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  body.fancybox-active {
    overflow: hidden;
  }

  body.fancybox-iosfix {
    position: fixed;
    left: 0;
    right: 0;
  }

  .fancybox-is-hidden {
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: hidden;
  }

  .fancybox-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99992;
    transform: translateZ(0);
    transform: translateZ(0);
    backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  .fancybox-outer,
  .fancybox-inner,
  .fancybox-bg,
  .fancybox-stage {
    position: absolute;
    inset: 0;
  }

  .fancybox-outer {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .fancybox-bg {
    opacity: 0;
    background: #1E1E1E;
    transition-timing-function: cubic-bezier(.47, 0, .74, .71);
    transition-duration: inherit;
    transition-property: opacity;
  }

  .fancybox-is-open .fancybox-bg {
    opacity: .87;
    transition-timing-function: cubic-bezier(.22, .61, .36, 1);
  }

  .fancybox-infobar,
  .fancybox-toolbar,
  .fancybox-caption-wrap {
    position: absolute;
    z-index: 99997;
    visibility: hidden;
    opacity: 0;
    box-sizing: border-box;
    direction: ltr;
    transition: opacity .25s, visibility 0s linear .25s;
  }

  .fancybox-show-infobar .fancybox-infobar,
  .fancybox-show-toolbar .fancybox-toolbar,
  .fancybox-show-caption .fancybox-caption-wrap {
    visibility: visible;
    opacity: 1;
    transition: opacity .25s, visibility 0s;
  }

  .fancybox-infobar {
    top: 0;
    left: 0;
    mix-blend-mode: exclusion;
    min-width: 44px;
    height: 44px;
    padding: 0 10px;
    color: #CCC;
    font-size: 13px;
    line-height: 44px;
    text-align: center;
    user-select: none;
    user-select: none;
    user-select: none;
    user-select: none;
    pointer-events: none;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  .fancybox-toolbar {
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
  }

  .fancybox-stage {
    z-index: 99994;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    direction: ltr;
  }

  .fancybox-is-closing .fancybox-stage {
    overflow: visible;
  }

  .fancybox-slide {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99994;
    backface-visibility: hidden;
    backface-visibility: hidden;
    display: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;
    outline: none;
    white-space: normal;
    text-align: center;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-overflow-scrolling: touch;
  }

  .fancybox-slide::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
  }

  .fancybox-is-sliding .fancybox-slide,
  .fancybox-slide--previous,
  .fancybox-slide--current,
  .fancybox-slide--next {
    display: block;
  }

  .fancybox-slide--image {
    overflow: visible;
  }

  .fancybox-slide--image::before {
    display: none;
  }

  .fancybox-slide--video .fancybox-content,
  .fancybox-slide--video iframe {
    background: #000;
  }

  .fancybox-slide--map .fancybox-content,
  .fancybox-slide--map iframe {
    background: #E5E3DF;
  }

  .fancybox-slide--next {
    z-index: 99995;
  }

  .fancybox-slide > * {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 53px 40px 40px;
    margin: 44px 0;
    overflow: auto;
    border-width: 0;
    background-color: #FFF;
    text-align: left;
    vertical-align: middle;
  }

  #info-promocode {
    max-width: 80%;

    .title {
      text-transform: initial;
    }
  }

  .fancybox-slide > title,
  .fancybox-slide > style,
  .fancybox-slide > meta,
  .fancybox-slide > link,
  .fancybox-slide > script,
  .fancybox-slide > base {
    display: none;
  }

  .fancybox-slide .fancybox-image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99995;
    transform-origin: top left;
    transform-origin: top left;
    transform-origin: top left;
    backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: default;
    padding: 0;
    margin: 0;
    overflow: visible;
    border: 0;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    user-select: none;
    user-select: none;
    user-select: none;
    user-select: none;
  }

  .fancybox-can-zoomOut .fancybox-image-wrap {
    cursor: zoom-out;
    cursor: zoom-out;
  }

  .fancybox-can-zoomIn .fancybox-image-wrap {
    cursor: zoom-in;
    cursor: zoom-in;
  }

  .fancybox-can-drag .fancybox-image-wrap {
    cursor: grab;
    cursor: grab;
  }

  .fancybox-is-dragging .fancybox-image-wrap {
    cursor: grabbing;
    cursor: grabbing;
  }

  .fancybox-image,
  .fancybox-spaceball {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
    padding: 0;
    margin: 0;
    border: 0;
    user-select: none;
    user-select: none;
    user-select: none;
    user-select: none;
  }

  .fancybox-spaceball {
    z-index: 1;
  }

  .fancybox-slide--iframe .fancybox-content {
    width: 80%;
    max-width: calc(100% - 100px);
    height: 80%;
    max-height: calc(100% - 88px);
    padding: 0;
    overflow: visible;
    background: #FFF;
  }

  .fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    background: #FFF;
  }

  .fancybox-error {
    cursor: default;
    width: 100%;
    max-width: 380px;
    padding: 40px;
    margin: 0;
    background: #FFF;
  }

  .fancybox-error p {
    padding: 0;
    margin: 0;
    color: #444;
    font-size: 16px;
    line-height: 20px;
  }

  /* Buttons */
  .fancybox-button {
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    width: 44px;
    height: 44px;
    padding: 10px;
    margin: 0;
    border: 0;
    border-radius: 0;
    outline: none;
    background: rgba(30, 30, 30, 60%);
    vertical-align: top;
    transition: color .3s ease;
  }

  .fancybox-button,
  .fancybox-button:visited,
  .fancybox-button:link {
    color: #CCC;
  }

  .fancybox-button:focus,
  .fancybox-button:hover {
    color: #FFF;
  }

  .fancybox-button[disabled] {
    cursor: default;
    opacity: .6;
    color: #CCC;
  }

  .fancybox-button svg {
    position: relative;
    display: block;
    overflow: visible;
    shape-rendering: geometricprecision;
  }

  .fancybox-button svg path {
    fill: currentcolor;
    stroke: currentcolor;
    stroke-linejoin: round;
    stroke-width: 3;
  }

  .fancybox-button--share svg path {
    stroke-width: 1;
  }

  .fancybox-button--play svg path:nth-child(2) {
    display: none;
  }

  .fancybox-button--pause svg path:nth-child(1) {
    display: none;
  }

  .fancybox-button--zoom svg path {
    fill: transparent;
  }

  /* Navigation arrows */
  .fancybox-navigation {
    display: none !important;
  }

  .fancybox-show-nav .fancybox-navigation {
    display: block;
  }

  .fancybox-navigation button {
    position: absolute;
    top: 50%;
    z-index: 99997;
    width: 60px;
    height: 100px;
    padding: 17px;
    margin: -50px 0 0;
    background: transparent;
  }

  .fancybox-navigation button::before {
    position: absolute;
    top: 30px;
    right: 10px;
    content: '';
    width: 40px;
    height: 40px;
    background: rgba(30, 30, 30, 60%);
  }

  .fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
  }

  .fancybox-navigation .fancybox-button--arrow_right {
    right: 0;
  }

  /* Close button on the top right corner of html content */
  .fancybox-close-small {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    cursor: pointer;
    width: 16px;
    height: auto;
    padding: 0;
    margin: 5px;
    border: 0;
    border-radius: 0;
    background: transparent;
  }

  /* Removed default close icon */
  .fancybox-close-small svg {
    display: none !important;
  }

  .fancybox-close-small::after {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    content: '×';
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-width: 0;
    border-radius: 50%;
    background-color: transparent;
    color: #888;
    font: 22px/30px Arial,'Helvetica Neue',Helvetica,sans-serif;
    font-weight: 300;
    text-align: center;
    transition: background-color .25s;
  }

  .fancybox-close-small:focus {
    outline: none;
  }

  .fancybox-close-small:focus::after {
    outline: 1px dotted #888;
  }

  .fancybox-close-small:hover::after {
    background: #EEE;
    color: #555;
  }

  .fancybox-slide--image .fancybox-close-small,
  .fancybox-slide--iframe .fancybox-close-small {
    top: 0;
    right: -40px;
  }

  .fancybox-slide--image .fancybox-close-small::after,
  .fancybox-slide--iframe .fancybox-close-small::after {
    color: #AAA;
    font-size: 35px;
  }

  .fancybox-slide--image .fancybox-close-small:hover::after,
  .fancybox-slide--iframe .fancybox-close-small:hover::after {
    background: transparent;
    color: #FFF;
  }

  .fancybox-is-scaling .fancybox-close-small,
  .fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
    display: none;
  }

  /* Caption */
  .fancybox-caption-wrap {
    left: 0;
    bottom: 0;
    right: 0;
    padding: 60px 2vw 0;
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 10%) 20%, rgba(0, 0, 0, 20%) 40%, rgba(0, 0, 0, 60%) 80%, rgba(0, 0, 0, 80%) 100%);
    pointer-events: none;
  }

  .fancybox-caption {
    padding: 30px 0;
    border-top: 1px solid rgba(255, 255, 255, 40%);
    color: #FFF;
    font-size: 14px;
    line-height: 20px;
    text-size-adjust: none;
  }

  .fancybox-caption a,
  .fancybox-caption button,
  .fancybox-caption select {
    position: relative;
    pointer-events: all;

    /* Fix IE11 */
  }

  .fancybox-caption a {
    color: #FFF;
    text-decoration: underline;
  }

  /* Loading indicator */
  .fancybox-slide > .fancybox-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99999;
    width: 50px;
    height: 50px;
    margin-top: -30px;
    margin-left: -30px;
    border: 6px solid rgba(100, 100, 100, 40%);
    border-top: 6px solid rgba(255, 255, 255, 60%);
    border-radius: 100%;
    background: transparent;
    animation: fancybox-rotate .8s infinite linear;
    animation: fancybox-rotate .8s infinite linear;
  }

  /* Transition effects */
  .fancybox-animated {
    transition-timing-function: cubic-bezier(0, 0, .25, 1);
  }

  /* transitionEffect: slide */
  .fancybox-fx-slide.fancybox-slide--previous {
    transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }

  .fancybox-fx-slide.fancybox-slide--next {
    transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  .fancybox-fx-slide.fancybox-slide--current {
    transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  /* transitionEffect: fade */
  .fancybox-fx-fade.fancybox-slide--previous,
  .fancybox-fx-fade.fancybox-slide--next {
    opacity: 0;
    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  }

  .fancybox-fx-fade.fancybox-slide--current {
    opacity: 1;
  }

  /* transitionEffect: zoom-in-out */
  .fancybox-fx-zoom-in-out.fancybox-slide--previous {
    transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    opacity: 0;
  }

  .fancybox-fx-zoom-in-out.fancybox-slide--next {
    transform: scale3d(.5, .5, .5);
    transform: scale3d(.5, .5, .5);
    opacity: 0;
  }

  .fancybox-fx-zoom-in-out.fancybox-slide--current {
    transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  /* transitionEffect: rotate */
  .fancybox-fx-rotate.fancybox-slide--previous {
    transform: rotate(-360deg);
    transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 0;
  }

  .fancybox-fx-rotate.fancybox-slide--next {
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    opacity: 0;
  }

  .fancybox-fx-rotate.fancybox-slide--current {
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  /* transitionEffect: circular */
  .fancybox-fx-circular.fancybox-slide--previous {
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    opacity: 0;
  }

  .fancybox-fx-circular.fancybox-slide--next {
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    opacity: 0;
  }

  .fancybox-fx-circular.fancybox-slide--current {
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1;
  }

  /* transitionEffect: tube */
  .fancybox-fx-tube.fancybox-slide--previous {
    transform: translate3d(-100%, 0, 0) scale(.1) skew(-10deg);
    transform: translate3d(-100%, 0, 0) scale(.1) skew(-10deg);
  }

  .fancybox-fx-tube.fancybox-slide--next {
    transform: translate3d(100%, 0, 0) scale(.1) skew(10deg);
    transform: translate3d(100%, 0, 0) scale(.1) skew(10deg);
  }

  .fancybox-fx-tube.fancybox-slide--current {
    transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }

  /* Share */
  .fancybox-share {
    max-width: 90%;
    padding: 30px;
    border-radius: 3px;
    background: #F4F4F4;
    text-align: center;
  }

  .fancybox-share h1 {
    margin: 0 0 20px;
    color: #222;
    font-weight: 700;
    font-size: 35px;
  }

  .fancybox-share p {
    padding: 0;
    margin: 0;
  }

  p.fancybox-share__links {
    margin-right: -10px;
  }

  .fancybox-share__button {
    display: inline-block;
    min-width: 130px;
    padding: 0 15px;
    margin: 0 10px 10px 0;
    border: 0;
    border-radius: 3px;
    background: #FFF;
    color: #FFF;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    white-space: nowrap;
    text-decoration: none;
    transition: all .2s;
    user-select: none;
    user-select: none;
    user-select: none;
    user-select: none;
  }

  .fancybox-share__button:hover {
    text-decoration: none;
  }

  .fancybox-share__button--fb {
    background: #3B5998;
  }

  .fancybox-share__button--fb:hover {
    background: #344E86;
  }

  .fancybox-share__button--pt {
    background: #BD081D;
  }

  .fancybox-share__button--pt:hover {
    background: #AA0719;
  }

  .fancybox-share__button--tw {
    background: #1DA1F2;
  }

  .fancybox-share__button--tw:hover {
    background: #0D95E8;
  }

  .fancybox-share__button svg {
    position: relative;
    top: -1px;
    width: 25px;
    height: 25px;
    margin-right: 7px;
    vertical-align: middle;
  }

  .fancybox-share__button svg path {
    fill: #FFF;
  }

  .fancybox-share__input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 15px;
    margin: 10px 0 0;
    border: 0;
    border-bottom: 2px solid #D7D7D7;
    outline: none;
    background: transparent;
    color: #5D5B5B;
    font-size: 14px;
  }

  /* Thumbs */
  .fancybox-thumbs {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99995;
    display: none;
    box-sizing: border-box;
    width: 212px;
    padding: 2px 2px 4px;
    margin: 0;
    background: #FFF;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-tap-highlight-color: transparent;
  }

  .fancybox-thumbs-x {
    overflow: auto hidden;
  }

  .fancybox-show-thumbs .fancybox-thumbs {
    display: block;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 212px;
  }

  .fancybox-thumbs > ul {
    position: absolute;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden auto;
    font-size: 0;
    white-space: nowrap;
    list-style: none;
  }

  .fancybox-thumbs-x > ul {
    overflow: hidden;
  }

  .fancybox-thumbs-y > ul::-webkit-scrollbar {
    width: 7px;
  }

  .fancybox-thumbs-y > ul::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
    background: #FFF;
  }

  .fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #2A2A2A;
  }

  .fancybox-thumbs > ul > li {
    position: relative;
    backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: pointer;
    float: left;
    box-sizing: border-box;
    width: 100px;
    max-width: calc(50% - 4px);
    height: 75px;
    max-height: calc(100% - 8px);
    padding: 0;
    margin: 2px;
    overflow: hidden;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

  li.fancybox-thumbs-loading {
    background: rgba(0, 0, 0, 10%);
  }

  .fancybox-thumbs > ul > li > img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
    max-height: none;
    user-select: none;
    user-select: none;
    user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
  }

  .fancybox-thumbs > ul > li::before {
    position: absolute;
    inset: 0;
    z-index: 99991;
    content: '';
    opacity: 0;
    border: 4px solid #4EA7F9;
    transition: all .2s cubic-bezier(.25, .46, .45, .94);
  }

  .fancybox-thumbs > ul > li.fancybox-thumbs-active::before {
    opacity: 1;
  }

  /* Styling for Small-Screen Devices */
  @media all and (width <= 800px) {
    .fancybox-thumbs {
      width: 110px;
    }

    .fancybox-show-thumbs .fancybox-inner {
      right: 110px;
    }

    .fancybox-thumbs > ul > li {
      max-width: calc(100% - 10px);
    }
  }
}

@include desktopStyles {
  #info-promocode {
    max-width: 850px;
  }
}

@include commonTabletDesktop {
  .info-promocode-lightbox {
    max-width: 850px;

    ul {
      li {
        @include icon(check, before, 's') {
          margin-top: 3px;
          margin-right: 17px;
          color: var(--c_green);
          line-height: 24.51px;
        }
        display: flex;
        margin-top: 14px;

        .bold-text {
          font-weight: 700;
        }
      }
    }
  }
}

@include mobileStyles {
  .fancybox-slide--iframe .fancybox-content {
    max-width: 100%;
    max-height: 100%;
  }

  .fancybox-slide > * {
    padding: 30px 20px 20px;
  }

  #info-promocode {
    max-width: 100%;
  }
}
