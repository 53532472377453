@use 'sass:math';
@use '../../base_css/abstracts/abstracts' as *;

@include commonStyles {
  //
  // Form vars
  //

  $fz-c-background: var(--c_white);
  $fz-c-border: var(--c_cloud);
  $fz-c-label: var(--c_black);
  $fz-c-text: var(--c_dark_grey);
  $fz-c-accent: var(--c_black);
  $fz-c-disabled: var(--c_dark_grey);
  $fz-col-gutter: 20px;
  $fz-row-gutter: 20px;

  //
  // Form styles
  //
  body {
    // background-color: antiquewhite; /* DEBUG */
  }

  input {
    &:focus {
      outline: none;
    }
  }

  .fk-file-input {
    @include icon(camera, after, 'm') {
      position: absolute;
      left: 10px;
      bottom: 9px;
      z-index: 1;
      color: var(--c_primary);
    }
    position: relative;
    padding: 0 10px 8px 40px;

    p {
      @include fontSize(16);
      line-height: 24px;
    }
  }

  .formz {
    input[type='text'],
    input[type='email'],
    input[type='phone'],
    input[type='password'],
    input[type='tel'],
    input[type='date'],
    textarea {
      width: 100%;
      padding: 0 10px 8px;
      border: 0;
      outline: 0;
      background-color: transparent;
      color: $fz-c-text;
      line-height: 24px;

      &:focus {
        outline: 0;
      }

      &:-webkit-autofill {
        box-shadow: inset 0 0 0 9999px white;
      }

      &::-webkit-datetime-edit-month-field {
        padding: 0 5px;
      }

      &::-webkit-datetime-edit-day-field {
        padding: 0 5px;
      }

      &::-webkit-datetime-edit-year-field {
        padding: 0 5px;
      }
    }

    .required-mark {
      &::after {
        content: ' *';
      }
    }

    input[type='text'].is-date {
      padding-left: 44px;
      background-image: url('../../../images/icons/calendar.svg');
      background-repeat: no-repeat;
      background-position: 12px 0;
    }

    input[type='date']::-webkit-clear-button {
      appearance: none;
      display: none;
    }

    input[type='date']::-ms-clear {
      display: none;
    }

    input[type='date']::-webkit-inner-spin-button {
      appearance: none;
      display: none;
    }

    input[type='date']::-webkit-calendar-picker-indicator {
      appearance: none;
      display: none;
    }

    textarea {
      width: calc(100% - 8px);
      min-height: 120px;
      margin-right: 14px;
      resize: vertical;
    }

    input[type='file'] {
      // display: none !important;

      // Put control over
      position: absolute;
      inset: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      width: 100%;

      // Label styled like a button
      & + label {
        @include button;
        position: absolute;
        top: 8px;
        bottom: 8px;
        right: 8px;
        cursor: pointer;
        width: auto;
        padding-top: 12px;
        padding-bottom: 12px;
        border: 1px solid var(--c_primary);
        background: transparent;
        color: var(--c_primary);

        &:hover:not(.disabled, :disabled) {
          background: var(--c_primary);
          color: var(--c_white);
        }

        &.disabled,
        &:disabled {
          background: var(--c_primary);
          color: var(--c_white);
        }

        & + input[type='text'] {
          width: calc(100% - 170px);
        }
      }
    }

    select {
      appearance: none;
      cursor: pointer;
      width: 100%;
      padding: 0 25px 8px 10px;
      border: 0;
      outline: 0;
      background: var(--c_white);
      background: var(--c_white) url('../../../images/icons/arrow_formz_select.svg') calc(100% - 10px) calc(100% - 15px) no-repeat;
      color: $fz-c-text;

      &.phone-prefix {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100px;
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: 0;
        background-color: #EEE;
        background-position-y: center;

        & ~ * {
          padding-left: 114px;
        }
      }

      &::-ms-expand {
        display: none;
      }
    }

    label {
      display: block;
      width: 100%;
      color: $fz-c-label;

      &.label-col {
        display: inline-block;
        width: auto;
        margin-right: 20px;
      }

      abbr {
        display: none;
      }

      span:not([class*='t-']) { // unless span is being formatted by a .t-* class
        margin-left: 4px;
        color: var(--c_black);
        font-size: 12px;
      }

      &.group-title {
        margin: 10px 0;
      }

      @media screen and (max-width: $small-mobile) {
        &.general-conditions {
          max-width: 80%;
          margin-top: 10px;
        }
      }
    }

    p.info {
      margin-top: 8px;
      color: #999EA5;

      span {
        color: var(--c_black);
      }
    }

    p.desc {
      margin: 10px 0 $fz-row-gutter 0;
      color: #999EA5;
    }

    p.fz-main-title {
      width: 100%;
      margin: 10px 0 $fz-row-gutter 0;
      color: var(--c_black);
    }

    p.fz-section-title {
      width: 100%;
      margin: 10px 0 $fz-row-gutter 0;
      color: var(--c_black);

      & + p.desc {
        margin-top: -10px;
      }

      span {
        margin-left: 20px;
        font-size: 12px;
      }
    }

    input[type='checkbox'],
    input[type='radio'] {
      appearance: inital;
    }

    fieldset.check-group {
      padding: 10px 14px 20px;
    }

    fieldset.fieldset-no-style {
      padding: 0;
      margin: 0;
      border: 0;
    }

    .radio-box-row {
      display: flex;
    }

    // Input phone with prefix
    .fz-phone-with-prefix {
      position: relative;
      padding-left: 90px;

      .intl-tel-input {
        position: static;

        .selected-flag {
          @include icon(arrow-down-fat, after, 'xs') {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            color: var(--c_primary);
            font-weight: bold;
          }
          padding-left: 0;

          .iti-arrow {
            display: none;
          }
        }

        &.separate-dial-code {
          &.allow-dropdown {
            &.iti-sdc-2,
            &.iti-sdc-3,
            &.iti-sdc-4,
            &.iti-sdc-5 {
              .selected-flag {
                width: 88px;
              }

              input[type='text'] {
                padding-left: 10px;
              }
            }
          }

          .selected-dial-code {
            padding-left: 0;
            padding-right: 10px;
            text-align: center;
          }

          .selected-flag {
            background-color: var(--c_background);
          }
        }
      }
    }
  }

  //
  // Formz grid
  //
  .fz-step {
    padding: 20px 20px 10px;
    margin-left: 0;
    margin-bottom: 30px;
    margin-right: 0;
    border: 0;
    background-color: #F9FAFD;
  }

  .fz-row {
    width: 100%;
    margin-bottom: $fz-row-gutter;
    overflow: hidden;

    &.cols-2 {
      display: flex;
      justify-content: space-between;

      .fz-col {
        flex: 0 0 calc(50% - #{$fz-col-gutter * 0.5});
        width: calc(50% - #{$fz-col-gutter * 0.5});
      }
    }

    &.cols-3 {
      .fz-col {
        width: calc(33.33% - #{ math.div($fz-col-gutter * 2, 3) });
        margin-left: $fz-col-gutter;
      }
    }

    .form-link {
      display: inline-block;
    }
  }

  .fz-col {
    float: left;
    width: 100%;

    &:first-child {
      margin-left: 0 !important;
    }
  }

  .fz-field {
    position: relative;
    width: 100%;
    border: 1px solid $fz-c-border;
    background: $fz-c-background;

    &.fz-field-date {
      @include icon(calendar, after, 's') {
        position: absolute;
        left: 10px;
        bottom: 10px;
        color: var(--c_primary);
      }
      position: relative;

      input {
        padding-left: 35px;
      }
    }

    &.fz-img-upload {
      display: flex;
      flex-direction: column-reverse;

      input[type='file'] {
        & + label {
          position: static;

          & + input {
            width: 100%;
            padding: 10px;
            text-align: center;
          }
        }
      }
    }

    label {
      padding: 8px 10px 6px;
    }

    &.fixed-small {
      max-width: 150px;
    }

    &.fz-focus {
      box-shadow: 0 2px 14px 0 rgba(174, 181, 207, 14%);
    }

    &.parsley-error {
      border: 1px solid var(--c_danger);

      * {
        color: var(--c_danger);

        &::placeholder { /* Firefox 19+ */
          color: var(--c_danger);
        }
      }
    }

    &.disabled {
      opacity: 0.3;
    }

    .show-password {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);

      .toggle-password-icon {
        color: var(--c_primary);
        font-size: 35px;
      }
    }
  }

  .fz-spacer {
    width: 100%;
    height: 1px;
    margin: $fz-row-gutter 0;
    border: 0;
    background-color: $fz-c-border;
  }

  .fz-error-container {
    .parsley-errors-list {
      li,
      div {
        @include fontSize(14);
        margin: 8px 0 0;
        color: var(--c_danger);
        line-height: 22px;
      }
    }
  }

  .fz-collapsable {
    &.fz-collapsed {
      height: 32px;
      overflow: hidden;
    }
  }

  html:not(.ie-9) {
    .formz {
      input[type='checkbox'],
      input[type='radio'] {
        position: absolute;
        appearance: none;
        margin-left: -9999px;
      }

      input[type='checkbox'] + label,
      input[type='radio'] + label {
        position: relative;
        cursor: pointer;
        padding: 0 0 0 30px;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid var(--c_grey_400);
          background-color: var(--c_white);
        }
      }

      input[type='radio'] + label {
        &::before {
          border-radius: 50%;
        }
      }

      .inline {
        input[type='checkbox'] + label,
        input[type='radio'] + label {
          display: inline;
          width: auto;
        }
      }

      input[type='checkbox']:disabled + label,
      input[type='radio']:disabled + label {
        opacity: 0.3;
      }

      input[type='checkbox']:checked + label {
        @include icon(check, after, 'xs') {
          position: absolute;
          top: 7px;
          left: 5px;
          color: var(--c_green);
        }

        &::before {
          border-color: var(--c_green);
          background: var(--c_white);
        }
      }

      input[type='radio']:checked + label {
        &::before {
          border-color: var(--c_green);
        }

        &::after {
          position: absolute;
          top: 3px;
          left: 3px;
          content: '';
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: var(--c_green);
        }
      }
    }
  }
}

@include mobileStyles {
  .fz-row.cols-2 {
    flex-wrap: wrap;
    margin-bottom: 0;

    .fz-col {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .fz-row {
    .form-link {
      &.js-reset-password {
        display: block;
        margin-top: 20px;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
      }
    }
  }

  .fz-row.cols-3 {
    .fz-col {
      margin-left: 0;
    }
  }

  .fk-file-input {
    p {
      @include fontSize(14);
      font-family: var(--font-sans);
      line-height: 24px;
    }
  }
}

.ada {
  .fz-field {
    border: 1px solid var(--c_dark_grey) !important;

    &.parsley-error {
      border: 1px solid var(--c_danger) !important;
    }
  }
}
