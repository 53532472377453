picture.inview,
.inview[data-background-src] {
  position: static;

  &.skeleton {
    &::before {
      position: absolute;
      inset: 0;
      content: '';
      background: linear-gradient(to right, var(--c_white) 8%, #FAFAFA 24%, #CFD2DF 84%);
      animation: placeHolderShimmer 2s linear 0s infinite normal forwards;
      will-change: opacity;
    }
  }

  img {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:not(.loaded) {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--c_white);
  }

  &.loaded {
    &::before {
      display: none;
    }

    img {
      opacity: 1;
    }
  }
}

.inview[data-background-src] {
  position: relative;
}
