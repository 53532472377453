@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

[data-tooltip],
[data-discounttooltip] {
  position: relative;
}

.basic-tooltip {
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
  width: 100%;
  width: max-content;
  max-width: 300px;
  padding: 20px 30px;
  border: 1px solid var(--c_primary);
  background: var(--c_white);
  text-align: center;
  text-transform: none;

  &::before {
    position: absolute;
    top: -7px;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    content: '';
    width: 0;
    height: 0;
    border-color: transparent transparent var(--c_white);
    border-style: solid;
    border-width: 0 7px 7px;
  }

  &::after {
    position: absolute;
    top: -9px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    content: '';
    width: 0;
    height: 0;
    border-color: transparent transparent var(--c_primary) transparent;
    border-style: solid;
    border-width: 0 8.5px 8.5px;
  }

  &.top {
    top: auto;
    bottom: calc(100% + 15px);

    &::before {
      top: auto;
      bottom: -7px;
      border-color: var(--c_white) transparent transparent;
      border-width: 7px 7px 0;
    }

    &::after {
      top: auto;
      bottom: -9px;
      border-color: var(--c_primary) transparent transparent transparent;
      border-width: 8.5px 8.5px 0;
    }
  }

  &.left {
    top: 50%;
    left: auto;
    right: calc(100% + 15px);
    transform: translateY(-50%);

    &::before {
      top: 50%;
      left: auto;
      right: -7px;
      transform: translateY(-50%);
      border-color: transparent transparent transparent var(--c_white);
      border-width: 7px 0 7px 7px;
    }

    &::after {
      top: 50%;
      left: auto;
      right: -9px;
      transform: translateY(-50%);
      border-color: transparent transparent transparent var(--c_primary);
      border-width: 8.5px 0 8.5px 8.5px;
    }
  }

  &.right {
    top: 50%;
    left: calc(100% + 15px);
    transform: translateY(-50%);

    &::before {
      top: 50%;
      left: -7px;
      transform: translateY(-50%);
      border-color: transparent var(--c_white) transparent transparent;
      border-width: 7px 7px 7px 0;
    }

    &::after {
      top: 50%;
      left: -9px;
      transform: translateY(-50%);
      border-color: transparent var(--c_primary) transparent transparent;
      border-width: 8.5px 8.5px 8.5px 0;
    }
  }

  &.shadow {
    box-shadow: 0 0 2px 0 rgba(#454545, 0.41);

    &.top {
      &::before {
        bottom: -5px;
      }
    }

    &::before {
      transform: rotate(-135deg);
      width: 10px;
      height: 10px;
      box-shadow: -1px -1px 1px 0 rgba(150, 150, 150, 41%);
      background-color: var(--c_white);
      background-image: none;
    }
  }

  &.big {
    width: 340px;
    max-width: 340px;
    padding: 20px;
  }

  .tooltip-title {
    @include fontSansSemiBold;
    @include fontSize(14);
    color: var(--c_dark_grey);
    line-height: 20px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  .tooltip-desc {
    @include fontSize(16);
    color: var(--c_dark_grey);
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
  }

  &.dark {
    box-shadow: 0 1px 2px 0 rgba(117, 124, 133, 23%);
    background: #00050F;

    .tooltip-title,
    .tooltip-desc {
      color: var(--c_white);
    }

    &::before {
      content: ' ';
      width: 0;
      height: 0;
      border-color: transparent transparent #00050F;
      border-style: solid;
      border-width: 0 8px 7px;
      background: transparent;
    }

    &.top {
      &::before {
        width: 0;
        height: 0;
        border-color: #00050F transparent transparent;
        border-style: solid;
        border-width: 7px 8px 0;
      }
    }
  }
}

.tooltip,
.tooltip-sash {
  position: absolute;
  z-index: 90;
  display: none;
  max-width: 200px;
  padding: 8px;
  border-radius: 4px;
  background-color: #000;
  color: var(--c_white);
  font-size: 12px;
  font-family: var(--font-sans);
  line-height: 120%;
  text-align: center;
  text-transform: none;

  span {
    display: block;
    font-weight: bold;
  }

  &.top {
    &::after {
      position: absolute;
      left: 50%;
      bottom: -5px;
      content: '';
      width: 0;
      height: 0;
      margin-left: -3px;
      border-color: #000 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 0;
    }
  }

  &.left {
    &::after {
      position: absolute;
      top: 50%;
      right: -5px;
      content: '';
      width: 0;
      height: 0;
      margin-top: -3px;
      margin-left: -3px;
      border-color: transparent transparent transparent #000;
      border-style: solid;
      border-width: 5px 0 5px 5px;
    }
  }
}
